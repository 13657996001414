/* eslint-disable react/no-danger */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';
import ArchivePostSingle from 'components/ArchivePostSingle';

// import { Main, Sharer, BlogVideo, GuestAuthor } from 'styles/blogPost';
// import { CustomerStoriesHero } from '../styles/customer-stories-styles';
import { signUp } from '../utils/contentProvider/function';
import 'styles/blogPost.scss';
import 'styles/customer-stories-styles.scss';
const BlogPostTemplate = ({
  data: {
    contentfulBlogPost: post,
    allContentfulBlogPost: { edges: relatedPosts = [] },
  },
  location,
}) => {
  const [stickyShare, setStickyShare] = useState({});

  const [stickyVideo, setStickyVideo] = useState({});

  const dateEl = useRef();
  const mainContainer = useRef();

  const scrollHandler = () => {
    if (dateEl.current && mainContainer.current) {
      const dateRect = dateEl.current.getBoundingClientRect();
      const containerRect = mainContainer.current.getBoundingClientRect();

      if (dateRect.top < -100 && window.innerWidth > 1200)
        setStickyShare({
          position: 'fixed',
          top: 90,
          left: dateRect.x - 100,
          ...(containerRect.bottom < 200
            ? { opacity: 0, pointerEvents: 'none' }
            : {}),
        });
      else setStickyShare({});
      if (dateRect.top < -100 && window.innerWidth > 1200)
        setStickyVideo({
          position: 'fixed',
          top: 90,
          right: dateRect.x - 20,
          ...(containerRect.bottom < 200
            ? { opacity: 0, pointerEvents: 'none' }
            : {}),
        });
      else setStickyVideo({});
    }
  };

  function contentClickHandler(e) {
    if (e.target.closest('.entry-content') && e.target.closest('a')) {
      e.preventDefault();

      if (e.target.href.startsWith('https://ruttl.com/'))
        navigate(new URL(e.target.href).pathname);
      else window.open(e.target.href);
    }
  }

  function getMetaDate(date) {
    let newDate = date;

    newDate = newDate.replace('th', '');
    newDate = newDate.replace('st', '');
    newDate = newDate.replace('nd', '');
    newDate = newDate.replace('rd', '');

    return new Date(newDate).toISOString();
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  useEffect(() => {
    window.addEventListener('click', contentClickHandler);

    return () => window.removeEventListener('click', contentClickHandler);
  });

  return (
    <Layout location={location}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title={`${post.title} | ruttl Blog`}
            description={post.excerpt.excerpt}
            author={post?.guestAuthor ? post?.guestName : post?.author.name}
            image={post.archiveThumbnail.resize.src}
            url={`https://ruttl.com/blog/${post.slug}/`}
            date={getMetaDate(post.publishDate)}
          />
          <main className="blog-post-styled-main" style={{ padding: 0 }}>
            <div className="customer-stories-hero">
              <div className="container">
                <div className="flex align-items-center">
                  <div className="flex-1">
                    <div className="flex">
                      {/* eslint-disable indent */}
                      {Array.isArray(post.category) &&
                        post.category.length > 0 && (
                          <span className="tag">
                            {post.category.map((c) => c.name).join(', ')}
                          </span>
                        )}
                      {/* eslint-enable indent */}
                    </div>
                    <h1>{post?.title}</h1>
                    <p>{post?.excerpt.excerpt}</p>
                  </div>

                  <div className="flex-1">
                    <div className="hero-video-iframe">
                      {post?.youTubeLink ? (
                        <iframe
                          src={post?.youTubeLink?.replace('watch?v=', 'embed/')}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                      ) : (
                        <img src="/assets/img/coming-soon-youtube.jpg" alt="" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container" ref={mainContainer}>
              <div className="blog-wrapper content-wrapper">
                <ul className="blog-post-sharer" style={stickyShare}>
                  <li>
                    <a
                      href={encodeURI(
                        `https://www.linkedin.com/shareArticle?mini=true&url=https://ruttl.com/blog/${post.slug}/&title=${post.title}&summary=${post.excerpt.excerpt}`,
                      )}
                      target="_blank"
                      rel="noopener noreferrer">
                      <svg
                        x="0px"
                        y="0px"
                        viewBox="0 0 36 36"
                        width="36px"
                        height="36px">
                        <g>
                          <path
                            className="st0"
                            d="M18.4,0.4C8.7,0.4,0.8,8.3,0.8,18s7.9,17.6,17.6,17.6S36,27.7,36,18S28.1,0.4,18.4,0.4z M14.8,24
                        c0,0.2-0.2,0.4-0.4,0.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-8c0-0.2,0.2-0.4,0.4-0.4h2c0.2,0,0.4,0.2,0.4,0.4V24z M13.2,14
                        c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6c0.4,0,0.8,0.2,1.1,0.5s0.5,0.7,0.5,1.1C14.8,13.3,14.1,14,13.2,14z M25.6,24
                        c0,0.2-0.2,0.4-0.4,0.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-4.6c0-0.5-0.2-0.9-0.6-1.2c-0.4-0.3-0.8-0.4-1.3-0.4c-0.8,0.2-1.3,0.9-1.3,1.7
                        V24c0,0.2-0.2,0.4-0.4,0.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-8c0-0.2,0.2-0.4,0.4-0.4h2c0.2,0,0.4,0.2,0.4,0.4v0.3c0.9-0.8,2-1.1,3.2-0.9
                        c1.7,0.4,2.9,1.9,2.8,3.6V24z"
                          />
                        </g>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href={encodeURI(
                        `https://www.facebook.com/sharer/sharer.php?u=https://ruttl.com/blog/${post.slug}/`,
                      )}
                      target="_blank"
                      rel="noopener noreferrer">
                      <svg
                        x="0px"
                        y="0px"
                        viewBox="0 0 36 36"
                        width="36px"
                        height="36px">
                        <g>
                          <path
                            className="st0"
                            d="M17.6,0C7.9,0,0,7.9,0,17.6s7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6S27.3,0,17.6,0z M21.6,12.4
                        c0,0.2-0.2,0.4-0.4,0.4h-2.4c-0.4,0-0.8,0.4-0.8,0.8V16h3.2c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.2,0,0.3l-0.6,1.8
                        c-0.1,0.2-0.2,0.3-0.4,0.3H18v6c0,0.2-0.2,0.4-0.4,0.4h-2c-0.2,0-0.4-0.2-0.4-0.4v-6H14c-0.2,0-0.4-0.2-0.4-0.4v-1.6
                        c0-0.2,0.2-0.4,0.4-0.4h1.2v-2.4c0-1.8,1.4-3.2,3.2-3.2h2.8c0.2,0,0.4,0.2,0.4,0.4V12.4z"
                          />
                        </g>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href={encodeURI(
                        `https://twitter.com/home?status=https://ruttl.com/blog/${post.slug}/ ${post.title}`,
                      )}
                      target="_blank"
                      rel="noopener noreferrer">
                      <svg
                        x="0px"
                        y="0px"
                        viewBox="0 0 36 36"
                        width="36px"
                        height="36px">
                        <g>
                          <path
                            className="st0"
                            d="M17.6,0C7.9,0,0,7.9,0,17.6s7.9,17.6,17.6,17.6s17.6-7.9,17.6-17.6S27.3,0,17.6,0z M25.6,13.2
                        c-0.4,0.6-0.9,1-1.5,1.4c0,0.1,0,0.3,0,0.4c0,2.6-1,5.1-2.9,7c-1.9,1.8-4.4,2.9-7,2.8c-1.5,0-3-0.3-4.4-1c-0.1,0-0.1-0.1-0.1-0.2
                        v-0.1c0-0.1,0.1-0.2,0.2-0.2c1.5,0,2.9-0.6,4.1-1.5c-1.3,0-2.6-0.8-3.1-2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.2-0.1
                        c0.4,0,0.8,0,1.2-0.1c-1.5-0.3-2.6-1.5-2.8-3c0-0.1,0-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2,0c0.4,0.2,0.8,0.3,1.3,0.3
                        c-1.3-0.9-1.9-2.5-1.4-4c0.1-0.1,0.2-0.2,0.3-0.3s0.3,0,0.4,0.1c1.8,1.9,4.2,3,6.7,3.1c-0.1-0.3-0.1-0.5-0.1-0.8
                        c0-1.4,0.9-2.7,2.2-3.2s2.8-0.2,3.8,0.8c0.7-0.1,1.3-0.4,1.9-0.7c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c-0.3,0.7-0.8,1.2-1.4,1.6
                        c0.5-0.1,1.1-0.2,1.6-0.4c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1C25.6,13.1,25.6,13.1,25.6,13.2z"
                          />
                        </g>
                      </svg>
                    </a>
                  </li>
                </ul>
                <p ref={dateEl} className="date">
                  {`Published on ${post.publishDate}`}
                </p>
                {post?.guestAuthor ? (
                  ''
                ) : (
                  <div className="author">
                    <Img
                      alt={post?.author.name}
                      fluid={post?.author.image.fluid}
                      className="author-image"
                      style={{ width: 65 }}
                    />
                    <p className="author-details">
                      <span>{post?.author.name}</span>
                      <br />
                      <span>{`${post?.author.title}, ${post?.author.company}`}</span>
                    </p>
                    <a
                      href="https://www.linkedin.com/company/ruttl/"
                      className="author-linkedin"
                      target="_blank"
                      rel="noopener noreferrer">
                      Follow ruttl on LinkedIn
                    </a>
                  </div>
                )}
                <div
                  className="entry-content"
                  dangerouslySetInnerHTML={{
                    __html: post.body.childMarkdownRemark.html,
                  }}
                />

                <div className="blog-post-blogvideo" style={stickyVideo}>
                  <div className="video-wrapper">
                    <video
                      src="/assets/video/ruttl-blog-video.mp4"
                      autoPlay
                      loop
                      muted></video>
                  </div>

                  <Link to="/" className="button">
                    Check Homepage
                  </Link>
                </div>
              </div>
              <div className="center">
                <button
                  type="button"
                  onClick={() => signUp(toggleSignup)}
                  className="button">
                  {/* <img
                src="/assets/img/social/linkedin-white.svg"
                alt="linkedin"
                width="20px"
                height="20px"
              /> */}
                  Get started with ruttl
                </button>
              </div>
              {post?.guestAuthor ? (
                <div className="blog-post-guestauthor">
                  <div className="flex align-items-center">
                    <div>
                      <Img
                        alt={post?.guestName}
                        fluid={post?.guestImage?.fluid}
                        className="guest-image"
                        style={{ width: 80 }}
                      />
                    </div>
                    <div className="guest-text">
                      <h4>{post?.guestName}</h4>
                      <p>
                        {post?.guestAuthorDescription?.guestAuthorDescription}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            {relatedPosts?.length > 0 && (
              <section className="related-post-main">
                <div className="container">
                  <h2 className="heading">Similar posts</h2>
                  <div className="flex flex-wrap" style={{ margin: '0 -15px' }}>
                    {relatedPosts.map(({ node }) => (
                      <ArchivePostSingle
                        key={node.id}
                        link={`/blog/${node.slug}/`}
                        title={node.title}
                        image={node.archiveThumbnail.fluid}
                        excerpt={node.excerpt.excerpt}
                      />
                    ))}
                  </div>
                </div>
              </section>
            )}
            <GetStartedCenter toggleSignup={toggleSignup} />
          </main>
        </>
      )}
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query ProductUpdateBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      excerpt {
        excerpt
      }
      category {
        name
      }
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(
          maxWidth: 1170
          maxHeight: 365
          quality: 70
          resizingBehavior: THUMB
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
        resize(width: 1200) {
          src
        }
      }
      archiveThumbnail {
        resize(width: 1200) {
          src
        }
      }
      author {
        name
        title
        company
        image {
          fluid(maxWidth: 65, maxHeight: 65) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      youTubeLink
      guestAuthor
      guestName
      guestAuthorDescription {
        guestAuthorDescription
      }
      guestImage {
        fluid(maxWidth: 100, maxHeight: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulBlogPost(limit: 3, filter: { slug: { nin: [$slug] } }) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`;
